import { useEffect, useState } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { Box, Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "./candy-machine";
import { mintMultipleToken } from "./candy-batch-machine";
import { SmsFailed } from "@material-ui/icons";

const MAX_MINT_COUNT = 10;
const MIN_MINT_COUNT = 1;

const ConnectButton = styled(WalletDialogButton)`
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  -webkit-font-smoothing: antialiased;
  font-family: inherit;
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  box-sizing: border-box;
  margin: 0 !important;
  display: inline-block !important;
  text-align: center !important;
  vertical-align: middle !important;
  user-select: none;
  border: 0;
  outline: 0;
  font-weight: 700 !important;
  box-shadow: none !important;
  line-height: 28px !important;
  border-radius: 124px !important;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transition: all 0.3s;
  cursor: pointer;
  background: #1a1b1f !important;
  color: #f9ff00 !important;
  font-size: 16px !important;
  margin-top: 29px !important;
  padding: 12px 35px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  &:hover {
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    box-sizing: border-box;
    margin: 0;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 0;
    font-weight: 700;
    line-height: 28px;
    border-radius: 124px;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    transition: all 0.3s;
    cursor: pointer;
    text-decoration: none;
    outline: 0;
    box-shadow: none;
    background: #f9ff00;
    font-size: 16px;
    margin-top: 29px;
    padding: 12px 35px;
    padding-left: 20px;
    padding-right: 20px;
    color: #1a1b1f;
  }
`;

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div`
  background-color: white;
  text-align: center;
`; // add your styles here

const MintButton = styled.button`
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  -webkit-font-smoothing: antialiased;
  font-family: inherit;
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  box-sizing: border-box;
  margin: 0;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 0;
  outline: 0;
  font-weight: 700;
  box-shadow: none;
  line-height: 28px;
  border-radius: 124px !important;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transition: all 0.3s;
  cursor: pointer;
  background: #1a1b1f;
  color: #f9ff00;
  font-size: 16px;
  margin-top: 29px;
  padding: 12px 35px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    box-sizing: border-box;
    margin: 0;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 0;
    font-weight: 700;
    line-height: 28px;
    border-radius: 124px;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    transition: all 0.3s;
    cursor: pointer;
    text-decoration: none;
    outline: 0;
    box-shadow: none;
    background: #f9ff00;
    font-size: 16px;
    margin-top: 29px;
    padding: 12px 35px;
    padding-left: 20px;
    padding-right: 20px;
    color: #1a1b1f;
  }
`; // add your styles here

const MinusButton = styled.span`
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  color: #fff;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font: 500 18px/28px "neue-haas-grotesk-display", sans-serif;
  cursor: auto;
  text-align: center !important;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 44px;
  height: 44px;
  border-radius: 500px;
  display: inline-block;
  vertical-align: middle;
  background-color: #1a1b1f;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transition: all 0.3s ease;
  background-image: url(https://d2od6v49a2xx9z.cloudfront.net/minus-alt.svg);
  &:hover {
    background-image: url(https://d2od6v49a2xx9z.cloudfront.net/minus.svg);
    color: #fff;
    background-color: #f9ff00;
  }
`; // add your styles here

const PlusButton = styled.span`
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  color: #fff;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font: 500 18px/28px "neue-haas-grotesk-display", sans-serif;
  cursor: auto;
  text-align: center !important;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 44px;
  height: 44px;
  border-radius: 500px;
  display: inline-block;
  vertical-align: middle;
  background-color: #1a1b1f;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transition: all 0.3s ease;
  background-image: url(https://d2od6v49a2xx9z.cloudfront.net/plus-alt.svg);
  &:hover {
    background-image: url(https://d2od6v49a2xx9z.cloudfront.net/plus.svg);
    color: #fff;
    background-color: #f9ff00;
  }
`; // add your styles here

const Counter = styled.input`
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  -webkit-font-smoothing: antialiased;
  font-family: inherit;
  overflow: visible;
  box-sizing: border-box;
  border: 0;
  outline: 0;
  width: 100%;
  margin: 0 15px;
  font-weight: 900;
  padding: 3px 10px;
  text-align: center;
  border-radius: 42px;
  background: #fbfce5;
  display: inline-block;
  vertical-align: middle;
  appearance: none;
  font-size: 60px;
  max-width: 130px;
  line-height: 60px;
`; // add your styles here

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT
  const [isWhiteListed, setIsWhiteListed] = useState(-1);

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);
  const [counterValue, setCounterValue] = useState(1);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
      } = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      console.log(candyMachine);

      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);

      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  };

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        // while (i < counterValue) {
        const signedTransactions: any = await mintMultipleToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury,
          counterValue
        );

        // status = await awaitTransactionSignatureConfirmation(
        //   mintTxId,
        //   props.txTimeout,
        //   props.connection,
        //   "singleGossip",
        //   false
        // );

        const promiseArray = [];

        for (let index = 0; index < signedTransactions.length; index++) {
          const tx = signedTransactions[index];
          promiseArray.push(
            awaitTransactionSignatureConfirmation(
              tx,
              props.txTimeout,
              props.connection,
              "singleGossip",
              true
            )
          );
        }

        const allTransactionsResult = await Promise.all(promiseArray);
        let totalSuccess = 0;
        let totalFailure = 0;

        for (let index = 0; index < allTransactionsResult.length; index++) {
          const transactionStatus = allTransactionsResult[index];
          if (!transactionStatus?.err) {
            totalSuccess += 1;
          } else {
            totalFailure += 1;
          }
        }

        // if (!status?.err) {
        //   i++;
        // } else {
        //   const message =
        //     i > 0
        //       ? `${i} Mints succeeded, but ${
        //           counterValue - i
        //         } failed! Please try again!`
        //       : `${counterValue} ${
        //           counterValue > 1 ? "mints" : "mint"
        //         } failed! Please try again!`;
        //   setAlertState({
        //     open: true,
        //     message: message,
        //     severity: "error",
        //   });
        //   // break;
        // }
        // }
        setAlertState({
          open: true,
          message: "Congratulations! Mint succeeded!",
          severity: "success",
        });
        if (wallet) {
          const balance = await props.connection.getBalance(wallet.publicKey);
          setBalance(balance / LAMPORTS_PER_SOL);
        }
        setIsMinting(false);
        refreshCandyMachineState();
        // Simulate a mouse click:
        setTimeout(function(){
          window.location.href = `https://www.awkmonks.com/thank-you-mint/?mtqy=${counterValue}`;
        }, 3000);
      }
    } catch (error: any) {
      // TODO: blech:
      console.error(error);
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });    
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();      
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  // useEffect(() => {
  //   (async () => {
  //     if (wallet && isWhiteListed < 1) {
  //       const url: string = `${process.env
  //         .REACT_APP_CHECK_STATUS_URL!}/checks/${wallet.publicKey}`;
  //       console.log("---- url:", url);
  //       const response = await fetch(url);
  //       const status = response.status;
  //       if (status === 200) {
  //         setIsWhiteListed(1);
  //       } else if (isWhiteListed < 0) {
  //         setIsWhiteListed(0);
  //       }
  //     }
  //   })();
  // }, [isWhiteListed, wallet]);

  useEffect(() => {
    counterValue < MIN_MINT_COUNT && setCounterValue(MIN_MINT_COUNT);
    counterValue > MAX_MINT_COUNT && setCounterValue(MAX_MINT_COUNT);
  }, [counterValue]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);

  return (
    <main>
      <MintContainer>
        {!wallet ? (
          <ConnectButton>Connect Wallet</ConnectButton>
        ) : (
          <>
            <div>{itemsAvailable} - {itemsRedeemed} - {itemsRemaining}</div>
            <Box>
              <MinusButton
                className="minus"
                onClick={() => setCounterValue(counterValue - 1)}
              />
              <Counter value={counterValue} />
              <PlusButton
                className="plus"
                onClick={() => setCounterValue(counterValue + 1)}
              />
            </Box>
            <MintButton
              disabled={isSoldOut || isMinting || !isActive}
              onClick={onMint}
            >
              {isSoldOut ? (
                "SOLD OUT"
              ) : isActive ? (
                isMinting ? (
                  <CircularProgress />
                ) : (
                  "Mint Now!"
                )
              ) : (
                <Countdown
                  date={startDate}
                  onMount={({ completed }) => completed && setIsActive(true)}
                  onComplete={() => setIsActive(true)}
                  renderer={renderCounter}
                />
              )}
            </MintButton>
          </>
        )}
      </MintContainer>

      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </main>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};

export default Home;
